import React from 'react'

import Layout from '../components/shared/components/Layout'
import DoughnutChart from '../components/webDetails/DoughnutChart'
import WebPageTestAttribution from '../components/shared/components/WebPageTestAttribution'
import Button from '../components/shared/components/Button'
import AppsSection from '../components/webDetails/AppsSection'
import TitleSection from '../components/webDetails/TitleSection'
import SEO from '../components/Seo'

const webDetails = ({ pageContext }) => {
  const { web } = pageContext
  const {
    name,
    url,
    category,
    performanceScore,
    bestPracticeScore,
    seoScore,
    SpeedIndex,
    requestsDoc,
    bytesInDoc,
    isHttps,
    redirectsHttp,
    TTFB,
    firstContentfulPaint,
    visualComplete85,
    visualComplete,
    loadTime,
    TimeToInteractive,
    linkimageScreenShot,
    apps
  } = web

  // List of each detail and how each Detail item should we render
  const detailRenderListDetails = {
    SpeedIndex: {
      title: 'Speed Index',
      content: SpeedIndex ? `${(SpeedIndex / 1000).toFixed(2)} s` : '-'
    },
    requestsDoc: { title: 'Number of Requests', content: requestsDoc ? `${requestsDoc}` : '-' },
    bytesInDoc: {
      title: 'Kb Loaded (Page weight)',
      content: bytesInDoc ? `${(bytesInDoc / 1000).toFixed(2)} Kbs` : '-'
    },
    isHttps: { title: 'HTTPS', content: isHttps ? `${isHttps}` : '-' },
    redirectsHttp: {
      title: 'Redirects to HTTPS',
      content: redirectsHttp ? `${redirectsHttp}` : '-'
    },
    TTFB: { title: 'TTFB', content: TTFB ? `${(TTFB / 1000).toFixed(2)} s` : '-' },
    firstContentfulPaint: {
      title: 'First Contentful Paint',
      content: firstContentfulPaint ? `${(firstContentfulPaint / 1000).toFixed(2)} s` : '-'
    },
    visualComplete85: {
      title: 'Visual Complete 85%',
      content: visualComplete85 ? `${(visualComplete85 / 1000).toFixed(2)} s` : '-'
    },
    visualComplete: {
      title: 'Visual Complete 100%',
      content: visualComplete ? `${(visualComplete / 1000).toFixed(2)} s` : '-'
    },
    TimeToInteractive: {
      title: 'Time to Interactive',
      content: TimeToInteractive ? `${(TimeToInteractive / 1000).toFixed(2)} s` : '-'
    },
    loadTime: {
      title: 'Load Time',
      content: loadTime ? `${(loadTime / 1000).toFixed(2)} s` : '-'
    }
  }

  // order that the items should be render
  const itemsShowOrderDetails = [
    'SpeedIndex',
    'requestsDoc',
    'bytesInDoc',
    'isHttps',
    'redirectsHttp',
    '',
    'TTFB',
    'firstContentfulPaint',
    'visualComplete85',
    'visualComplete',
    'loadTime',
    'TimeToInteractive'
  ]

  const detailItem = item =>
    item && detailRenderListDetails[item] ? (
      <div key={detailRenderListDetails[item].title}>
        <div className="flex items-center justify-between px-4 mb-2">
          <h3 className="justify-start leading-none">{detailRenderListDetails[item].title}</h3>
          <p className="justify-end leading-none">{detailRenderListDetails[item].content}</p>
        </div>
        <hr className="mb-4" />
      </div>
    ) : (
      <div key={item} className="invisible">
        <div className="flex items-center justify-between px-4 mb-2">
          <h3 className="justify-start leading-none">Title</h3>
          <p className="justify-end leading-none">text</p>
        </div>
        <hr className="mb-4" />
      </div>
    )

  // Map each item of itemsShowOrderDetails and render each one on a list
  const detailItems = itemsShowOrderDetails.map(webKey => detailItem(webKey))

  const appsItem = item =>
    item ? (
      <div key={item}>
        <div className="flex items-center justify-between px-4 mb-2">
          <h3 className="justify-start leading-none">{item}</h3>
          <p className="justify-end leading-none">{apps[item]}</p>
        </div>
        <hr className="mb-4" />
      </div>
    ) : (
      <div key={item} className="invisible">
        <div className="flex items-center justify-between px-4 mb-2">
          <h3 className="justify-start leading-none">Title</h3>
          <p className="justify-end leading-none">text</p>
        </div>
        <hr className="mb-4" />
      </div>
    )

  const relevantApps = [
    'CDN',
    'Web Servers',
    'Programming Languages',
    'PHP',
    'CMS',
    'Blogs',
    'Web Application Frameworks',
    'Operating Systems'
  ]

  // Save each relevant key of apps object
  const availableApps = apps
    ? relevantApps.filter(itemKey => Object.prototype.hasOwnProperty.call(apps, itemKey))
    : []

  // Render a list of app items
  const appsItems = availableApps.map(itemKey => appsItem(itemKey))

  const backButtonRoute = {
    'Universities - Arequipa': '/rankings/university',
    'ASX 100': '/rankings/asx100',
    'S&P 500': '/rankings/sp500',
    'FTSE 100': '/rankings/ft100'
  }

  return (
    <Layout>
      <SEO title={`Ranking ${name} Page`} />
      <div className="container mx-auto px-8 py-8">
        <Button className="mb-4 pl-6" navigateTo={backButtonRoute[category]}>
          &larr; Go back
        </Button>

        <TitleSection category={category} name={name} url={url} imageSource={linkimageScreenShot} />
        <div className="flex flex-col text-center lg:flex-row align-middle items-center mb-8">
          <div className="flex justify-center items-center mb-2 w-1/3 flex-col">
            <h3 className="my-2">Performance Score</h3>
            <DoughnutChart dataset={performanceScore} />
          </div>

          <div className="flex justify-center items-center mb-2 w-1/3 flex-col">
            <h3 className="my-2">Best Practice Score</h3>
            <DoughnutChart dataset={bestPracticeScore} />
          </div>

          <div className="flex justify-center items-center mb-2 w-1/3 flex-col">
            <h3 className="my-2">SEO Score</h3>
            <DoughnutChart dataset={seoScore} />
          </div>
        </div>
      </div>

      {/* Details Table Section */}
      <div className="bg-gray-100 py-8">
        <div className="container mx-auto px-8 py-8">
          <h2 className="mb-8">Details</h2>
          {/* Column */}
          <div className="grid xs:grid-cols-1 lg:grid-cols-2 grid-flow-row gap-4">
            {/* Rows */}
            <div className="grid grid-cols-1 grid-flow-row gap-4">
              {detailItems.slice(0, Math.floor(detailItems.length / 2))}
            </div>
            <div className="grid grid-cols-1 grid-flow-row gap-4">
              {detailItems.slice(Math.floor(detailItems.length / 2), detailItems.length)}
            </div>
          </div>
        </div>
      </div>

      {/* Apps Table Section */}
      <AppsSection appsItems={appsItems} />

      <div className="container mx-auto px-8 py-8">
        <h2 className="mb-8">Network Waterfall Chart</h2>
        <a href={web.linkDetails} target="_blank" rel="noopener noreferrer">
          Full results (WebpageTest)
        </a>
        <br />
        {/* <img src={web.linkImageWaterfall} alt={`${name} Performance Waterfall`} /> */}
        <WebPageTestAttribution />
      </div>
    </Layout>
  )
}

export default webDetails
