import React from 'react'

const AppsSection = ({ appsItems }) => {
  return (
    appsItems &&
    appsItems.length > 0 && (
      <div className="container mx-auto px-8 py-8">
        <h2 className="mb-8">Platform</h2>
        {/* Column */}
        <div className="grid xs:grid-cols-1 lg:grid-cols-2 grid-flow-row gap-4">
          {/* Rows */}
          <div className="grid grid-cols-1 grid-flow-row gap-4">{appsItems}</div>
          <div className="grid grid-cols-1 grid-flow-row gap-4" />
        </div>
      </div>
    )
  )
}

export default AppsSection
