/* eslint-disable no-nested-ternary */
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const DoughnutChart = ({ dataset }) => {
  const options = {
    legend: { display: false },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    }
  }

  const datasetPorcentage = (dataset * 100).toFixed(0)

  const charColor =
    datasetPorcentage < 90 ? (datasetPorcentage < 50 ? '#db2825' : '#FDCA40') : 'green'

  const data = {
    labels: ['Performance Score', 'Missing'],
    datasets: [
      {
        data: [datasetPorcentage, 100 - datasetPorcentage],
        backgroundColor: [charColor, '#e0e0e0'],
        hoverBackgroundColor: [charColor, '#e0e0e0']
      }
    ]
  }

  return (
    <div className="relative z-0 w-64">
      <Doughnut options={options} data={data} />
      <h2 className="text-3xl absolute pin-c">{datasetPorcentage}</h2>
    </div>
  )
}

export default DoughnutChart
