import React from 'react'

const TitleSection = ({ category, name, url, imageSource }) => {
  return (
    <section className="flex items-center justify-center mb-10">
      <div className="container mx-auto px-4 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2 ">
          <p className="mt-4 pl-1 -mb-4 text-primarygray">{category}</p>
          <h1 className="mb-1">{name}</h1>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <p className="mb-8 lg:mb-0">{url}</p>
          </a>
        </div>

        <div className="lg:w-1/2 flex items-center justify-center">
          {/* <img alt={`${name} website`} src={imageSource} className="h-48" /> */}
        </div>
      </div>
    </section>
  )
}

export default TitleSection
